/* eslint-disable max-len */
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Leaf, Organization } from './models';
import { OrganizationManager } from './managers/organization-manager';
import iconFlag from '../../assets/images/common/icon_flag.svg';

type Props = {
  organizations: Organization[],
  onClick: (leaf: Leaf) => void,
  selectOrg: string,
  selectList?: { id: string, label: string }[],
  disabledOrg?: string,
  isOrgMarge?: boolean,
};

const LeafComponent = (props: { leaf: Leaf, onClick: (leaf: Leaf) => void }) => {
  const { leaf, onClick } = props;
  const label = useRef<SVGTextElement>(null);
  // labelの周りのrectのwidth
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    /** svgのtextのwidthによってrectを調整する処理 */
    const width = label.current?.getBBox()?.width || 0;
    setLabelWidth(leaf.level === 0 ? width + 20 : width);
  }, []);
  //
  const color = '#666';
  const strokeWidth = 1;
  // - parent -
  const parentLevel = leaf.parent?.level || 0;
  const parentIndex = leaf.parent?.index || 0;
  // - rect -
  const height = 30;
  const dx = leaf.level * 30;
  const dy = leaf.index * 40;
  // - path pos -
  const fromPos = {
    x: parentLevel * 30 + 10,
    y: parentIndex * 40 + height,
  };
  const toPos = {
    x: dx,
    y: dy + (height / 2),
  };
  // - callback -
  // -- click --
  const callbackClickLeaf = useCallback(
    () => {
      onClick(leaf);
    },
    [leaf],
  );
  return (
    <g>
      <g
        style={{
          cursor: `${leaf.isDisabled ? 'default' : 'pointer'}`,
        }}
        transform={`translate(${dx}, ${dy})`}
        onClick={leaf.isDisabled ? () => {} : callbackClickLeaf}
      >
        <rect
          width={labelWidth + 20}
          height={height}
          // fill="rgba(0, 0, 0, 0)"
          fill={ leaf.isDisabled ? 'rgb(210,210,210)'
            : leaf.isActive
              ? '#72383D'
              : 'rgba(0, 0, 0, 0)'}
          stroke={color}
          // stroke={ leaf.isActive ? 'red' : color }
          strokeWidth={strokeWidth}
        />
        {/* level0のleafの場合、旗のアイコンを表示 */}
        {leaf.level === 0
          ? (
            <image
              href={iconFlag}
              x="8"
              y="6"
            />
          )
          : <></>
        }
        <text
          ref={label}
          // stroke={color}
          fontSize={14}
          dy={20}
          dx={leaf.level === 0 ? 30 : 10}
          fill={leaf.isActive ? '#fff' : '#322D29'}
        >
          {leaf.label}
        </text>
      </g>
      {leaf.parent && (
        <g>
          <path
            stroke={color}
            strokeWidth={strokeWidth}
            fill="none"
            d={`M ${fromPos.x} ${fromPos.y} L ${fromPos.x} ${toPos.y}  L ${toPos.x} ${toPos.y}`}
          />
        </g>
      )}
    </g>
  );
};

export const TreeView = (props: Props) => {
  const { organizations, onClick, selectOrg, selectList, disabledOrg, isOrgMarge } = props;
  // ---------------------------- useState ----------------------------
  const [leafList, setLeafList] = useState<Leaf[]>([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  // ---------------------------- useRef ----------------------------
  const svgGroupRef = useRef<SVGGElement>(null);
  const svgEle = useRef<SVGSVGElement>(null);
  // ---------------------------- useEffect ----------------------------
  // useEffect(() => {
  // if (!svgEle.current) {
  //   return;
  // }
  // const svgManager = new SvgManager(svgEle.current);
  // console.log(svgManager);
  // }, []);
  /** organizationデータをleafデータに変換する */
  useEffect(() => {
    const organizationManager = new OrganizationManager();
    const organizationsSetChildren = organizationManager.setChildrenProps(organizations);
    const leafListTemp = organizationManager.convertOrganizations2LeafList(organizationsSetChildren);
    setLeafList(leafListTemp);
  }, [organizations]);
  useEffect(
    () => {
      if (!svgGroupRef || !svgGroupRef.current) {
        return;
      }
      const ele = svgGroupRef.current;
      const bbox = ele.getBBox();
      console.log('bbox-width', bbox.width);
      setWidth(bbox.width);
      setHeight(bbox.height);
      let selectLeaf = leafList.find(element => element.id === selectOrg);
      if (selectLeaf) {
        selectLeaf.isActive = true;
        onClick(selectLeaf);
      }
      const bottomOrgAllDisabled = (l: Leaf) => {
        if (!l.children.length) return;
        l.children.forEach((child) => {
          child.isDisabled = true;
            bottomOrgAllDisabled(child);
        })
      }
      let disabledLeaf = leafList.find(element => element.id === disabledOrg);
      if (disabledLeaf) {
        disabledLeaf.isDisabled = true;
        if (isOrgMarge) {
          bottomOrgAllDisabled(disabledLeaf)
        }
      }
      if (selectList) {
        selectList.forEach((v) => {
          let selectLeaf = leafList.find(element => element.id === v.id);
          if (selectLeaf) {
            selectLeaf.isActive = true;
            onClick(selectLeaf);
          }

        });
      }
    },
    // [leafList, width],
    [leafList, svgGroupRef.current?.getBBox().width, svgGroupRef.current?.getBBox().height],
  );


  // ---------------------------- DOM ----------------------------
  return (
    <div
      style={{
        textAlign: 'center',
        // maxHeight: '500px',
        // maxWidth: '600px',
        // overflow: 'scroll',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`${-10} ${-10} ${width + 20} ${height + 20}`}
        width={`${width + 20}`}
        height={`${height + 20}`}
        ref={svgEle}
      >
        <g ref={svgGroupRef}>
          {leafList
            .map((leaf) => (
              <g key={leaf.id}>
                <LeafComponent
                  leaf={leaf}
                  onClick={onClick}
                />
              </g>
            ))}
        </g>
      </svg>
    </div>
  );
};
